import { StaticQuery, graphql } from 'gatsby';

import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default (props) => {

    const imgFDrupal = (node) => { 
        let styleValue = {maxWidth:`100%`}
        if (node.attribs["data-align"]) {
            styleValue.float = node.attribs["data-align"]
            if(node.attribs["data-align"] == `center`) {
                styleValue.display = `block`
                styleValue.marginRight = `auto`
                styleValue.marginLeft = `auto`
                styleValue.float = null
            }
        }
        return styleValue
    }

    return (
        <StaticQuery
            query={graphql`
                query inlineBodyTransform {
                    allFileFile{
                        edges {
                            node {
                                uri {
                                    url
                                }
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }`}
            render={(data) => {

                const files = data.allFileFile
                let post = props.bodyValue
                if (post != null) {post = post.value}
                
                props.bodySearch ? post = props.bodySearch : null

                let body_elements = post
                //  Images
                body_elements = new ReactHtmlParser(body_elements, {
                transform: function transform(node) {

                    if (node.type === 'tag') {
                        //  Images
                        if (node.name === 'img') {
                            let src = node.attribs["src"];
                            let i = 0;
                            for (i = 0; i < files.edges.length; i++) {
                                if (files.edges[i].node.uri.url === src && files.edges[i].node.localFile) {
                                    if(!props.bodySearch){
                                        let styleValue = imgFDrupal(node)
                                        let classValue = "mx-2"
                                        if (node.attribs["class"]){
                                            classValue = ` ${node.attribs["class"]}`
                                        }
                                        return (
                                            <img
                                                key={i}
                                                alt={node.attribs["alt"] ? node.attribs["alt"] : ""}
                                                data-entity-type="file"
                                                data-entity-uuid={node.attribs["data-entity-uuid"] ? node.attribs["data-entity-uuid"] : ""}
                                                src={encodeURI(files.edges[i].node.localFile.publicURL)}
                                                width={(node.attribs["width"]) && node.attribs["width"]}
                                                height={(node.attribs["height"]) && node.attribs["height"]}
                                                style={styleValue}
                                                className={classValue}
                                            />
                                        )
                                    } else {/* remove images in search */ return (null)}
                                }
                            }
                        }
                        //  Links
                        if (node.name === 'a') {
                            let href = node.attribs["href"];
                            let i = 0;
                            for (i = 0; i < files.edges.length; i++) {
                                if (files.edges[i].node.uri.url === href && files.edges[i].node.localFile) {
                                    if(!props.bodySearch){
                                        let dataTag = ``
                                        if (node.children[0].name === 'img') {
                                            let ci = 0
                                            for (ci = 0; ci < files.edges.length; ci++) {
                                                if (files.edges[ci].node.uri.url === node.children[0].attribs["src"] && files.edges[ci].node.localFile) {
                                                    let styleValue = imgFDrupal(node.children[0])
                                                    let classValue = "mx-2"
                                                    if (node.attribs["class"]){
                                                        classValue = ` ${node.attribs["class"]}`
                                                    }
                                                    dataTag = (
                                                    <img
                                                        alt={node.children[0].attribs["alt"] ? node.children[0].attribs["alt"] : ""}
                                                        data-entity-type="file"
                                                        data-entity-uuid={node.children[0].attribs["data-entity-uuid"] ? node.children[0].attribs["data-entity-uuid"] : ""}
                                                        src={encodeURI(files.edges[ci].node.localFile.publicURL)}
                                                        width={(node.children[0].attribs["width"]) && node.children[0].attribs["width"]}
                                                        height={(node.children[0].attribs["height"]) && node.children[0].attribs["height"]}
                                                        style={styleValue}
                                                        className={classValue}
                                                    />)
                                                } 
                                            }
                                        }
                                        else {
                                            dataTag = node.children[0].data
                                        }
                                        
                                        return (
                                            <a
                                                key={i}
                                                data-entity-type="file"
                                                data-entity-uuid={node.attribs["data-entity-uuid"] ? node.attribs["data-entity-uuid"] : ""}
                                                className={node.attribs["class"] ? node.attribs["class"] : ""}
                                                target={node.attribs["target"] ? node.attribs["target"] : ""}
                                                href={encodeURI(files.edges[i].node.localFile.publicURL)}
                                            >{dataTag}</a>
                                        )
                                    } else {return (null)}
                                }
                            }
                        }
                    }
                    return undefined;
                },
            });
        return(<>{body_elements}</>)
    }}
    />
)}